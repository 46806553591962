import Moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import uk from 'date-fns/locale/uk';
import { getActiveLanguage, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { TimePicker } from 'UI/Inputs';

class DatePickerFromTo extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());
		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		if (props.values != null && props.type == "assignment") {
			this.state = {
				timeStart: props.values.startDate != null && !this.props.cloneView ? Moment.utc(props.values.startDate).local() : Moment(),
				timeEnd: props.values.dueDate != null && !this.props.cloneView ? Moment.utc(props.values.dueDate).local()
					: this.props.values.startDate && !this.props.cloneView ? Moment.utc(props.values.startDate).local().add(7, 'days') : Moment().add(7, 'days'),
				dtPickerStartDate: props.values.startDate != null && !this.props.cloneView ? Moment.utc(props.values.startDate).local() : Moment(),
				dtPickerDueDate: props.values.dueDate != null && !this.props.cloneView ? Moment.utc(props.values.dueDate).local()
					: this.props.values.startDate && !this.props.cloneView ? Moment.utc(props.values.startDate).local().add(7, 'days') : Moment().add(7, 'days'),
				updated: false
			};
		} else if (props.values != null && props.type == "plan") {
			this.state = {
				timeStart: props.values.timeStart != null && !this.props.cloneView ? Moment.utc(props.values.timeStart).local() : Moment(),
				timeEnd: props.values.timeEnd != null && !this.props.cloneView ? Moment.utc(props.values.timeEnd).local() : Moment().add(7, 'days'),
				dtPickerStartDate: props.values.timeStart != null && !this.props.cloneView ? Moment.utc(props.values.timeStart).local() : Moment(),
				dtPickerDueDate: props.values.timeEnd != null && !this.props.cloneView ? Moment.utc(props.values.timeEnd).local() : Moment().add(7, 'days'),
				updated: false
			};
		} else if (props.values != null && props.values.start != null && props.values.end != null) {
			this.state = {
				timeStart: props.values.start != null ? Moment.utc(props.values.start).local() : Moment(),
				timeEnd: props.values.end != null ? Moment.utc(props.values.end).local() : Moment().add(7, 'days'),
				dtPickerStartDate: props.values.start != null ? Moment.utc(props.values.start).local() : Moment(),
				dtPickerDueDate: props.values.end != null ? Moment.utc(props.values.end).local() : Moment().add(7, 'days'),
				updated: false
			};
		} else if (props.values != null && props.type == "timeblocks") {
			this.state = {
				timeStart: props.values.startTime != null ? Moment.utc(props.values.startTime).local() : Moment(),
				timeEnd: props.values.endTime != null ? Moment.utc(props.values.endTime).local() : Moment().add(7, 'days'),
				dtPickerStartDate: props.values.startTime != null ? Moment.utc(props.values.startTime).local() : Moment(),
				dtPickerDueDate: props.values.endTime != null ? Moment.utc(props.values.endTime).local() : Moment().add(7, 'days'),
				updated: false
			};
		} else if (props.values == null && props.type == "timeblocks") {
			this.state = {
				timeStart: Moment(),
				timeEnd: Moment(),
				dtPickerStartDate: Moment(),
				dtPickerDueDate: Moment(),
				updated: false
			};
		} else {
			this.state = {
				timeStart: Moment(),
				timeEnd: Moment().add(7, 'days'),
				dtPickerStartDate: Moment(),
				dtPickerDueDate: Moment().add(7, 'days'),
				updated: false
			};
		}

	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.values != null) {
			if (nextProps.type == "assignment") {
				if (nextProps.values == null) {
					return;
				}

				this.setState({
					timeStart: Moment.utc(nextProps.values.startDate).local(),
					timeEnd: Moment.utc(nextProps.values.dueDate).local(),
					dtPickerStartDate: Moment.utc(nextProps.values.startDate).local(),
					dtPickerDueDate: Moment.utc(nextProps.values.dueDate).local(),
					updated: true
				});
			} else if (nextProps.type == "plan") {
				if (nextProps.values == null) {
					return;
				}

				this.setState({
					timeStart: nextProps.values.timeStart ? Moment.utc(nextProps.values.timeStart).local() : Moment(),
					timeEnd: nextProps.values.timeEnd ? Moment.utc(nextProps.values.timeEnd).local() : Moment().add(7, 'days'),
					dtPickerStartDate: nextProps.values.timeStart ? Moment.utc(nextProps.values.timeStart).local() : Moment(),
					dtPickerDueDate: nextProps.values.timeEnd ? Moment.utc(nextProps.values.timeEnd).local() : Moment().add(7, 'days'),
					updated: true
				});
			} else if (nextProps.type == "timeblocks") {
				this.setState({
					timeStart: nextProps.values.startTime != null ? Moment.utc(nextProps.values.startTime).local() : Moment(),
					timeEnd: nextProps.values.endTime != null ? Moment.utc(nextProps.values.endTime).local() : Moment().add(7, 'days'),
					dtPickerStartDate: nextProps.values.startTime != null ? Moment.utc(nextProps.values.startTime).local() : Moment(),
					dtPickerDueDate: nextProps.values.endTime != null ? Moment.utc(nextProps.values.endTime).local() : Moment().add(7, 'days'),
					updated: false
				});
			} else if (nextProps.values.start != null && nextProps.values.end != null) {
				this.setState({
					timeStart: Moment.utc(nextProps.values.start).local(),
					timeEnd: Moment.utc(nextProps.values.end).local(),
					dtPickerStartDate: Moment.utc(nextProps.values.start).local(),
					dtPickerDueDate: Moment.utc(nextProps.values.end).local(),
					updated: false
				});
			}
		}
	}

	componentDidMount = () => {
		this.onChange();
	}

	onStartChange = (date) => {
		if (date == null) return null;
		let { dtPickerStartDate, timeStart, dtPickerDueDate, timeEnd } = this.state;
		dtPickerStartDate = date;
		timeStart = date;

		var dueDate = Moment(date);
		date = Moment(date);
		if (date.diff(dtPickerDueDate) > 0) {
			dtPickerDueDate = Moment(date);
			timeEnd = Moment(date);
		}

		if (this.props.type == "timeblocks") {
			if (!date.isSame(timeEnd, 'day')) {
				dtPickerDueDate = Moment(date);
				timeEnd = Moment(date);
			}
		}

		this.setState({ dtPickerDueDate, timeEnd, dtPickerStartDate, timeStart }, () => {
			this.onChange();
		});
	}

	onEndChange = (date) => {
		if (date == null) return null;
		let { dtPickerDueDate, timeEnd, dtPickerStartDate, timeStart } = this.state;
		dtPickerDueDate = date;
		timeEnd = date;

		if (this.props.type == "timeblocks") {
			if (!date.isSame(timeStart, 'day')) {
				dtPickerStartDate = Moment(date);
				timeStart = Moment(date);
			}
		}

		this.setState({ dtPickerDueDate, timeEnd, dtPickerStartDate, timeStart }, () => {
			this.onChange();
		});
	}

	onStartTimeChange = (time) => {
		let { dtPickerStartDate, timeStart } = this.state;
		dtPickerStartDate = Moment(time);
		timeStart = Moment(time);

		this.setState({ dtPickerStartDate, timeStart }, () => {
			this.onChange();
		});
	}

	onEndTimeChange = (time) => {
		let { dtPickerDueDate, timeEnd } = this.state;
		dtPickerDueDate = Moment(time);
		timeEnd = Moment(time);

		this.setState({ dtPickerDueDate, timeEnd }, () => {
			this.onChange();
		});
	}

	onChange = () => {
		if (this.props.onChange) {
			const dates = {
				start: this.state.timeStart,
				end: this.state.timeEnd,
			};

			this.props.onChange(dates);
		}
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK") {
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		return (
			<div>
				<div className="form-row-half">
					{this.props.timePicker ?
						<div>
							<div className="form-row-half">
								<label htmlFor="date-start">{this.props.translate(this.props.customStartDateLabel ?? 'start')}</label>
								<DatePicker
									selected={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
									startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
									endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
									dateFormat={this.getDateFormat()}
									onChange={this.onStartChange}
									popperModifiers={{
										preventOverflow: {
											enabled: true,
										},
										positionFixed: false,
									}}
									selectsStart
									popperPlacement="bottom-start"
									showWeekNumbers
									fixedHeight
									previousMonthButtonLabel=""
									nextMonthButtonLabel=""
									locale={this.normalizeLocale(getActiveLanguage())}
									{...this.state.startDate}
								/>
							</div>

							<div className="form-row-half last">
								<label htmlFor="">{this.props.translate('start-time')}</label>
								<TimePicker
									placeholder={this.props.translate('pick-start-time')}
									time={this.state.dtPickerStartDate}
									onChange={this.onStartTimeChange}
								/>
							</div>
						</div>
						:
						<div>
							<label htmlFor="date-start">{this.props.translate('start')}</label>
							<DatePicker
								selected={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
								startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
								endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
								dateFormat={this.getDateFormat()}
								onChange={this.onStartChange}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								popperPlacement="bottom-start"
								selectsStart
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=""
								nextMonthButtonLabel=""
								locale={this.normalizeLocale(getActiveLanguage())}
								{...this.state.startDate}
							/>
						</div>
					}
				</div>

				<div className="form-row-half last">
					{this.props.timePicker ?
						<div>
							{!this.props.hideEndDate ?
								<div>
									<div className="form-row-half">
										<label htmlFor="date-end">{this.props.translate('end')}</label>
										<DatePicker
											selected={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
											startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
											endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
											minDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
											dateFormat={this.getDateFormat()}
											onChange={this.onEndChange}
											popperModifiers={{
												preventOverflow: {
													enabled: true,
												},
												positionFixed: false,
											}}
											popperPlacement="bottom-end"
											selectsEnd
											showWeekNumbers
											isClearable={true}
											fixedHeight
											previousMonthButtonLabel=""
											nextMonthButtonLabel=""
											locale={this.normalizeLocale(getActiveLanguage())}
											{...this.state.endDate}
										/>
									</div>

									<div className="form-row-half last">
										<label htmlFor="">{this.props.translate('end-time')}</label>
										<TimePicker
											placeholder={this.props.translate('pick-end-time')}
											time={this.state.dtPickerDueDate}
											onChange={this.onEndTimeChange}
										/>
									</div>
								</div>
								:
								<div className="form-row-half">
									<label htmlFor="">{this.props.translate('end-time')}</label>
									<TimePicker
										placeholder={this.props.translate('pick-end-time')}
										time={this.state.dtPickerDueDate}
										onChange={this.onEndTimeChange}
									/>
								</div>
							}
						</div>
						:
						<div>
							{!this.props.hideEndDate ?
								<div>
									<label htmlFor="date-end">{this.props.translate('end')}</label>
									<DatePicker
										selected={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
										startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
										endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
										minDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
										dateFormat={this.getDateFormat()}
										onChange={this.onEndChange}
										popperPlacement="bottom-end"
										positionFixed={true}
										popperModifiers={{
											preventOverflow: {
												enabled: true,
											},
											positionFixed: false,
										}}
										selectsEnd
										showWeekNumbers
										fixedHeight
										previousMonthButtonLabel=""
										nextMonthButtonLabel=""
										locale={this.normalizeLocale(getActiveLanguage())}
										{...this.state.endDate}
									/>
								</div>
								: null}
						</div>
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages
	};
}

export default connect(mapStateToProps)(DatePickerFromTo);
