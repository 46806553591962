import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

import { PRESCHOOL } from 'constants/schoolTypes';
import { setPageTitle } from 'actions/header';

import WelcomeMessage from 'containers/WelcomeMessage';

import MyPosts from './Sections/MyPosts';
import MyPlans from './Sections/MyPlans';
import MyAssignments from './Sections/MyAssignments';
import Schedule from '../Schedule/Schedule';
import { InView } from 'react-intersection-observer';
import LoadingCard from 'components/Cards/LoadingCard';
import MyAssignmentsWrapper from 'containers/Assignments/MyAssignmentsWrapper';

class Overview extends Component {
	constructor(props) {
		super(props);
	}

	UNSAFE_componentWillMount() {
		this.props.setPageTitle(this.props.translate('home-navitem-overview'));
	}

	render() {
		const school = this.props.user.schools.find((school) => {
			return school.id == this.props.activeSchool;
		});

		return (
			<div className='overview'>
				<WelcomeMessage />

				{this.props.services.news ? (
					<div className='section' style={{ minHeight: '250px' }}>
						<MyPosts overview />
					</div>
				) : null}

				{this.props.services.plans ? (
					<div className='section' style={{ minHeight: '250px' }}>{<MyPlans dateRestriction overview />}</div>
				) : null}

				{(this.props.services.haldor || this.props.services.microsoft) && school.type != PRESCHOOL ? (
					<InView as="div" delay={850} triggerOnce={true}>
						{({ inView, ref, entry }) => {
							return (
								<div className='section' style={{ minHeight: '200px' }} ref={ref}>
									{inView ? <MyAssignmentsWrapper showHeaderIcon pageSize={8} overview /> : <LoadingCard count={1}></LoadingCard>}
								</div>
							)
						}}
					</InView>
				) : null}

				{this.props.services.schedule && school.type != PRESCHOOL ? (
					<InView as="div" delay={1300} triggerOnce={true}>
						{({ inView, ref, entry }) => {
							return (
								<div className='section' style={{ minHeight: '200px' }} ref={ref}>
									{inView ? <Schedule overview /> : <LoadingCard count={1}></LoadingCard>}
								</div>
							)
						}}
					</InView>) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		services: state.Services.availableServices,
		activeSchool: state.user.activeSchool,
	};
}

export default connect(mapStateToProps, {
	setPageTitle,
})(Overview);
