import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Tooltip } from '@mui/material';
import { getHaldorAssignments, clearAssignmentsFromStore } from 'actions/assignments';
import { Floating, Button as HaldorButton, Icon, Select, translate as translateConstructor } from '@haldor/ui';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';

import AssignmentTaskStatusNotStarted from 'UI/IconsSrc/assignment_task_status_not_started';
import AssignmentTaskStatusStarted from 'UI/IconsSrc/assignment_task_status_started';
import AssignmentTaskStatusSubmitted from 'UI/IconsSrc/assignment_task_status_submitted';
import AssignmentTaskStatusNotComplete from 'UI/IconsSrc/assignment_task_status_not_complete';
import AssignmentTaskStatusCanDevelop from 'UI/IconsSrc/assignment_task_status_can_develop';
import AssignmentTaskStatusNotAssigned from 'UI/IconsSrc/assignment_task_status_not_assigned';

import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';
import moment from 'moment';

import './AssignmentsStudentsProgress.scss';
import NoItemsFoundLayout from 'containers/Assignments/NoItemsFoundLayout';
import { assignmentsStudentsProgress_assignmentsDateFilter_key } from 'helpers/localstorage';

const useStyles = makeStyles({
	root: {
		position: 'relative',
		height: 500,
		width: '100%',
		backgroundColor: '#FFFFFF',
		'& .MuiDataGrid-columnHeaders': {
			backgroundColor: '#f0f0f0',
			color: '#333',
			fontSize: '.688rem',
			cursor: 'unset !important',
			textOverflow: 'ellipsis !important',
		},
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: 'normal !important',
		},
		'& .MuiDataGrid-cell': {
			'&:hover': {
				backgroundColor: '#f0f0f0', // Custom hover effect
			},
			pointerEvents: 'none', // Disable click actions
		},
	},
	firstColumn: {
		boxShadow: '5px 0 10px #0000000d;',
		fontSize: '.688rem',
	},
});

const selectActiveSection = createSelector(
	(state) => state.sections,
	(sections) => sections.activeSection
);

const selectAssignments = createSelector(
	(state) => state.assignments,
	(assignments) => assignments.assignments
);

const AssignmentsStudentsProgress = ({ groupId }) => {
	if (groupId == null) return null;

	const dispatch = useDispatch();
	const classes = useStyles();
	const apiRef = useGridApiRef();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const activeSection = useSelector(selectActiveSection);
	const assignments = useSelector(selectAssignments);
	const translations = useSelector(state => state.Languages.translations);
	const status = useSelector(state => state.status.status);

	let translate = translateConstructor(translations);

	const renderAssignmentStatusIcon = (params, assignmentTaskStatusIcons) => {
		const icon = assignmentTaskStatusIcons.find(item => item.id === params?.value)?.icon;
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: '18px', marginTop: '5px' }}>{icon}</div>
			</div>
		);
	};

	// Memoize the assignmentTaskStatusIcons to prevent reloading all the time
	const assignmentTaskStatusIcons = useMemo(() => ([
		{ id: 'ASSIGNMENT_TASK_NOT_STARTED', icon: <AssignmentTaskStatusNotStarted /> },
		{ id: 'ASSIGNMENT_TASK_STARTED', icon: <AssignmentTaskStatusStarted /> },
		{ id: 'ASSIGNMENT_TASK_SUBMITTED', icon: <AssignmentTaskStatusSubmitted /> },
		{ id: 'ASSIGNMENT_TASK_NOT_COMPLETE', icon: <AssignmentTaskStatusNotComplete /> },
		{ id: 'ASSIGNMENT_TASK_CAN_DEVELOP', icon: <AssignmentTaskStatusCanDevelop /> },
		{ id: 'ASSIGNMENT_TASK_NOT_ASSIGNED', value: 'Not assigned', icon: <AssignmentTaskStatusNotAssigned /> }
	]), []);


	const columns = useMemo(() => {
		if (!assignments || assignments.length < 2) return [{
			field: 'name',
			headerName: 'Name',
			minWidth: 150,
			disableColumnMenu: true,
			align: 'left',
			flex: 1,
			cellClassName: classes.firstColumn,
		}];

		// Create a copy of the assignments array before sorting
		const sortedAssignments = [...assignments].sort((a, b) => {
			if (!a.dueDate) return 1;
			if (!b.dueDate) return -1;
			return new Date(b.dueDate) - new Date(a.dueDate);
		});

		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 150,
				disableColumnMenu: true,
				align: 'left',
				cellClassName: classes.firstColumn,
			},
			...(sortedAssignments?.map((assignment) => {
				const headerContent = <div>{assignment.title} <br />{moment.utc(assignment.dueDate).local().format('YYYY-MM-DD')}</div>;
				return {
					field: `assignment-${assignment.id}`,
					renderHeader: (params) => (
						<Tooltip title={headerContent} placement="bottom" arrow>
							<div className='hover-link' style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
								{assignment.title} <br />
								{moment.utc(assignment.dueDate).local().format('YYYY-MM-DD')}
							</div>
						</Tooltip>
					),
					width: 130,  // Dynamically calculate the minWidth based on content length
					align: 'center',
					headerAlign: 'left',
					disableColumnMenu: true,
					renderCell: (params) => renderAssignmentStatusIcon(params, assignmentTaskStatusIcons),
				};
			}) || []),
		]
	}, [assignments]);

	useEffect(() => {
		if (!activeSection || activeSection.id !== groupId) return;
		var storedAssignmentsDateFilter = localStorage.getItem(assignmentsStudentsProgress_assignmentsDateFilter_key);

		/** set storedAssignmentsDateFilter otherwise set default dates */
		if (storedAssignmentsDateFilter) {
			var dateFilter = JSON.parse(storedAssignmentsDateFilter);
			setStartDate(moment(dateFilter.startDate));
			setEndDate(moment(dateFilter.endDate));
		} else {
			setStartDate(moment().month() < 7 ? moment().month(0).date(1) : moment().month(6).date(1));
			setEndDate(moment().month() < 7 ? moment().month(6).date(31) : moment().month(11).date(31));
		}


		if (!assignments) {
			// If assignments aren't present, fetch them
			setIsLoading(true);

			dispatch(getHaldorAssignments(activeSection.graphId, moment(startDate).format('YYYY-MM-DD'), moment(endDate).add(1, 'd').format('YYYY-MM-DD'), "ASSIGNMENTTASK"));
		} else {
			var rows = assignments && assignments.length > 0 ? activeSection.students.map((student) => {
				var row = { id: student.userId, name: `${student.firstName} ${student.lastName}` };

				assignments.forEach((assignment) => {
					var assignmentTask = assignment.tasks.find((task) => task.assignmentTaskAssignees.find(assigne => assigne.assigneeId === student.userId));
					row[`assignment-${assignment.id}`] = assignmentTask ? assignmentTask.status : 'ASSIGNMENT_TASK_NOT_ASSIGNED';
				});

				return row;
			}) : [];

			// If assignments are present, set rows and loading state to false
			apiRef.current.setRows(rows);
			setIsLoading(false);
		}
	}, [groupId, activeSection, assignments, dispatch, apiRef]);

	const updateData = () => {
		if (activeSection != null) {
			dispatch(clearAssignmentsFromStore());
			setIsLoading(true);
		}
	}

	const onTermChange = (value) => {
		if (value != '') {
			let dStartDate = moment();
			let dDueDate = moment();

			switch (value) {
				case 'year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(7).date(1);
						dDueDate = moment().add(1, 'year').month(6).date(31);
					}

					break;
				case 'previous-year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(2, 'year').month(7).date(1);
						dDueDate = moment().subtract(1, 'year').month(6).date(31);
					}
					else {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().subtract(0, 'year').month(6).date(31);
					}
					break;
				case 'semester':
					if (moment().month() < 7) {
						dStartDate = moment().month(0).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(6).date(1);
						dDueDate = moment().month(11).date(31);
					}

					break;
			}


			setStartDate(dStartDate);
			setEndDate(dDueDate);

			storeDateFilter(dStartDate, dDueDate);
		}
	}

	const storeDateFilter = (startDate, endDate) => {
		if (startDate == null || endDate == null) return;
		var assignmentsDateFilter = { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') };
		localStorage.setItem(assignmentsStudentsProgress_assignmentsDateFilter_key, JSON.stringify(assignmentsDateFilter));
	}

	const onDateChange = (dates) => {
		setStartDate(dates.start);
		setEndDate(dates.end);

		storeDateFilter(dates.start, dates.end);
	};

	const onHeaderClick = (params) => {
		var assignmentId = params.field.split('-')[1];
		if (assignmentId == null) return;
		history.push('/assignment/' + assignmentId);
	}

	return (
		<div>
			{!isLoading ? <Grid className='mb-3' container justifyContent="flex-start" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12} md={9}>
					<div className="form">
						<div className="form-row">
							<DatePickerFromTo
								values={{
									start: moment(startDate),
									end: moment(endDate),
								}}
								onChange={onDateChange}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<div className='term-change' style={{ paddingBottom: '1.0rem' }}>
						<Select
							onChange={onTermChange}
							options={[
								{
									label: translate('current-schoolyear'),
									value: 'year',
								},
								{
									label: translate('previous-schoolyear'),
									value: 'previous-year',
								},
								{
									label: translate('current-semester'),
									value: 'semester',
								},
							]}
						>
							<strong style={{ marginBottom: '0.2rem' }}>{translate('select-period')}</strong>
						</Select>
					</div>
					<HaldorButton type='secondary' onClick={updateData}>{translate('Update')}</HaldorButton>
					<div className='student-progress-helper'>
						<Floating trigger={(<Icon name="Help" />)}>
							<div className="helper--content">
								<strong>{translate('Sign explanation')}</strong>

								{assignmentTaskStatusIcons.map((item) => {
									let currentStatus = status?.assignmentTaskStatus.find(t => t.id === item.id);

									return <div className="helper--content--item df aic">
										<div className='icon'>
											{item.icon}
										</div>
										{currentStatus != null ? currentStatus.value : null}
										{currentStatus == null && item.value != null ? translate(item.value) : null}
									</div>
								})}
							</div>
						</Floating>
					</div>
				</Grid>
			</Grid> : null}
			<div className={classes.root}>
				<DataGridPro
					apiRef={apiRef}
					loading={isLoading}
					slotProps={{
						loadingOverlay: {
							variant: 'skeleton',
							noRowsVariant: 'skeleton',
						},
					}}
					slots={{
						noRowsOverlay: () => (<NoItemsFoundLayout title={'No progress found'} />),
					}}
					checkboxSelection={false}
					disableColumnSorting
					disableColumnResize={false}
					disableColumnReorder
					disableColumnSelector
					hideFooter
					onColumnHeaderClick={onHeaderClick}
					columns={columns}
					initialState={{ pinnedColumns: { left: ['name'] } }}
				/>
			</div>
		</div>
	);
};

export default AssignmentsStudentsProgress;
