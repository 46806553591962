

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { getEducationGroups } from 'actions/sections';
import { clearAssignmentsFromStore, clearAssignmentEventPaginationFromStore } from 'actions/assignments';

import MyAssignments from 'containers/Overview/Sections/MyAssignments';
import moment from 'moment';
import { Icon, TooltipMenu, Button, translate as translateConstructor } from '@haldor/ui';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TableRowsIcon from '@mui/icons-material/TableRows';
import WidgetsIcon from '@mui/icons-material/Widgets';
import Grid from '@mui/material/Grid';
import { isUserStudent, isUserTeacher } from 'helpers/user';
import MyAssignmentsTableAsStaff from 'containers/Assignments/MyAssignmentsTableAsStaff';
import MyAssignmentsTableAsStudent from 'containers/Assignments/MyAssignmentsTableAsStudent';
import SectionTitle from 'components/Presentation/SectionTitle';
import Modal from 'containers/Modals/Modal';
import swal from 'sweetalert2';
import { isMicrosoftTeams } from 'helpers/teams';
import { getRootUrl } from 'helpers/url';
import * as microsoftTeams from '@microsoft/teams-js';

import { setPageTitle, setPageActions } from 'actions/header';

import AssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import MyAssignmentsLogs from './MyAssignmentsLogs';
import { Stack } from '@mui/material';

import AssignmentsStudentsProgress from 'components/List/AssignmentsStudentsProgress';

import { assignmentsStudentsProgress_assignmentsDateFilter_key, myAssignmentsWrapper_selectedTab_key } from 'helpers/localstorage';

function MyAssignmentsWrapper({ showHeaderIcon, pageSize, overview, groupId }) {
	const [serviceEnabled, setServiceEnabled] = useState(false);
	const [currentLayout, setCurrentLayout] = useState('old_assignment_layout');
	const [showAssignmentHeader, setShowAssignmentHeader] = useState(false);
	const [groupAssignmentFormModal, showGroupAssignmentFormModal] = useState(false);
	const [multipleAssignmentFormModal, showMultipleAssignmentFormModal] = useState(false);
	const [missingWorkspace, setMissingWorkspace] = useState(false);
	const [currentSelectedTab, setCurrentSelectedTab] = useState(null);
	const [currentGroupId, setCurrentGroupId] = useState(null);
	const [isTeacher, setIsTeacher] = useState(false);

	const translations = useSelector(state => state.Languages.translations);
	const tenant = useSelector(state => state.user.tenant);
	const currentUser = useSelector(state => state.user.currentUser);
	const sections = useSelector(state => state.sections.educationGroups);
	const activeSection = useSelector(state => state.sections.activeSection);
	const services = useSelector(state => state.Services.availableServices);
	const assignments = useSelector(state => state.assignments.assignments);
	const teamsAssignments = useSelector(state => state.assignments.teamsAssignments);

	/**
	 * Retrieves the hash value from the URL.
	 */
	const hash = window.location.hash;
	let translate = translateConstructor(translations);

	const dispatch = useDispatch();
	const history = useHistory();

	/**
	 * This useEffect will run when the groupId is changed. Which happens when the user navigates to section.
	 */
	useEffect(() => {
		const clearAssignmentEventPagination = async () => {
			await dispatch(clearAssignmentEventPaginationFromStore());
			setCurrentGroupId(groupId);
		};

		if (groupId) {
			clearAssignmentEventPagination();
		}
	}, [groupId]);

	useEffect(() => {
		if (currentSelectedTab != null) {
			localStorage.setItem(myAssignmentsWrapper_selectedTab_key, currentSelectedTab);
		}
	}, [currentSelectedTab]);

	useEffect(() => {
		if (sections && sections.length > 0) {
			checkIfWorkspaceExists();
		}
	}, [sections, assignments, teamsAssignments]);

	useEffect(() => {
		if (isTeacher && (!overview && groupId == null)) {
			let actions = [];

			if (getRootUrl() == '/' || getRootUrl() == '/assignments-tab/' || getRootUrl() == '/haldor-tab/') {
				actions.push({
					type: 'button',
					value: translate('task-create-button'),
					onClick: () => showMultipleAssignmentFormModal(true),
					buttonType: 'primary',
					icon: 'plus',
				});
			}

			actions.push({
				type: 'button',
				value: translate('create-group-assignment'),
				onClick: () => showGroupAssignmentFormModal(true),
				buttonType: 'primary',
				icon: 'plus',
			});

			dispatch(setPageTitle(translate('tasks-header-overview')));
			dispatch(setPageActions(actions));
		}
	}, [isTeacher]);

	useEffect(() => {
		// If the hash is set in the URL, we want to set the currentSelectedTab to the hash value
		// Otherwise we want to set the currentSelectedTab to the default value #assignments
		var recentlySelectedTab = localStorage.getItem(myAssignmentsWrapper_selectedTab_key);
		if (recentlySelectedTab != null) {
			setCurrentSelectedTab(recentlySelectedTab);
		}
		else
			setCurrentSelectedTab("#assignments");

		if (sections == null || sections.length == 0) {
			dispatch(getEducationGroups());
		}

		var savedLayoutView = localStorage.getItem("assigment_layoutView");
		if (savedLayoutView) { setCurrentLayout(savedLayoutView); }

		const assignmentServices = tenant.tenantServices.find(service => {
			return (service.serviceId == 'HALDOR_ASSIGNMENTS' || service.serviceId == 'MS_ASSIGNMENTS') && moment(service.serviceEnd).isSameOrAfter(moment(), 'day');
		});

		setServiceEnabled(assignmentServices != null);

		if (isMicrosoftTeams() && window.location.pathname == '/haldor-tab/assignments') {
			dispatch(setPageTitle(translate('tasks-header-overview')));
		}

		if (groupId || showHeaderIcon) {
			setShowAssignmentHeader(true);
		}

		setIsTeacher(isUserTeacher(currentUser));

		return () => {

		};
	}, []);

	const checkIfWorkspaceExists = () => {
		// If groupId is provided in the URL, we can use that to find the section
		if (groupId != null) {
			let section = sections.find((section) => section.id == groupId);
			if (section) {
				setMissingWorkspace(section.classroomUrl == null || section.classroomUrl == '');
			}
		} else {
			// If groupId is not provided in the URL, we can use the context to find the section if user using the teams
			if (microsoftTeams.app.isInitialized()) {
				microsoftTeams.app.getContext().then(async (context) => {
					if (context.team != null && context.team.groupId != null) {
						let section = sections.find((section) => section.graphId == context.team.groupId);
						if (section != null) {
							setMissingWorkspace(section.classroomUrl == null || section.classroomUrl == '');
						}
					}
				});
			}
		}
	}

	const renderCreateAssignmentButton = () => {
		if (!serviceEnabled) {
			return null;
		}

		const isTeacher = isUserTeacher(currentUser);

		if (isTeacher) {
			if (missingWorkspace) {
				return (
					<div className='create-container' style={{ display: 'inline-block' }}>
						<Button type='secondary' onClick={showMissingWorkspaceNotice}>
							<Icon name='Plus' /> {translate('Create')}
						</Button>
					</div>
				);
			}

			return (
				<div className='create-container' style={{ display: 'inline-block' }}>
					<TooltipMenu
						trigger={
							<Button type='secondary'>
								<Icon name='Plus' /> {translate('Create')}
							</Button>
						}
					>
						<TooltipMenu.Item onClick={() => showGroupAssignmentFormModal(true)}>
							{translate('create-group-assignment')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={() => showMultipleAssignmentFormModal(true)}>
							{translate('task-create-button')}
						</TooltipMenu.Item>
					</TooltipMenu>
				</div>
			);
		}
	};

	const setLayout = (newLayout) => {
		if (newLayout) {
			localStorage.setItem("assigment_layoutView", newLayout);
			dispatch(clearAssignmentsFromStore());
			setCurrentLayout(newLayout);
		}
	}

	const renderNewTableLayout = (groupId) => {
		const isTeacher = isUserTeacher(currentUser);
		const isStudent = isUserStudent(currentUser);

		if (isTeacher) {
			return (
				<div>
					<MyAssignmentsTableAsStaff pageSize={pageSize} section={groupId != null ? activeSection : null} />
				</div>
			);
		}

		if (isStudent) {
			return (
				<div>
					<MyAssignmentsTableAsStudent pageSize={pageSize} section={groupId != null ? activeSection : null} />
				</div>
			);
		}

		return null;
	}

	const renderOldLayout = (groupid) => {
		return (
			<div>
				<Stack spacing={2}>
					<p style={{ fontWeight: 'bold', color: 'red' }}>
						{translate('Showing data between')}{' '}
						{moment().subtract(6, 'months').format('YYYY-MM-DD')}{' '}
						{translate('to').toLowerCase()}{' '}
						{moment().format('YYYY-MM-DD')}
					</p>
					<MyAssignments
						overview={false}
						groupid={groupid}
						disableTitle={window.location.pathname == '/haldor-tab/assignments'}
					/>
				</Stack>
			</div>
		);
	}

	const onTabChange = (event, nextTab) => {
		let previousTab = currentSelectedTab;

		// Clear the assignments from the store when switching between assignments and assignmentStudentsProgress
		// This is to avoid showing the wrong assignments when switching between the tabs, becuause the assignments are loadeded with expand.
		if (nextTab == "#assignmentStudentsProgress" || (previousTab == "#assignmentStudentsProgress" && nextTab == "#assignments")) {
			dispatch(clearAssignmentsFromStore());
		}

		setCurrentSelectedTab(nextTab);
	};

	const showMissingWorkspaceNotice = () => {
		let text = translate(
			'A configuration problem has occurred with one of your groups. This means that the group is missing a workspace in Sharepoint and no assignments or plans can be created.'
		);
		text +=
			' ' +
			translate(
				'Please wait and try again later. If the problem persists, please contact your school for support.'
			);

		swal.fire({
			title: translate('Workspace missing'),
			text: text,
			showCancelButton: false,
			confirmButtonText: translate('Ok'),
		}).then((result) => { });
	};

	if (!serviceEnabled) {
		return (
			<div style={{ width: '80%', maxWidth: '625px', margin: '0 auto', textAlign: 'center' }}>
				{translate('service-not-active-error')}
			</div>
		);
	}

	const assignmentFormParams = {
		onAbort: () => { showGroupAssignmentFormModal(false) },
		editView: false,
		cloneView: false,
		groupId
	};

	return (
		<div className="overview">
			<Modal
				isOpen={groupAssignmentFormModal}
				title={
					<span>
						{translate('create-group-assignment')}{' '}
					</span>
				}
				onClose={() => { showGroupAssignmentFormModal(false) }}
			>
				<AssignmentForm
					{...assignmentFormParams}
					groupid={groupId}
					editView={false}
				/>
			</Modal>

			<Modal
				isOpen={multipleAssignmentFormModal}
				title={
					<span>
						{translate('task-create-button')}{' '}
					</span>
				}
				onClose={() => { showMultipleAssignmentFormModal(false); }}
			>
				<MultipleAssignmentForm
					groupId={activeSection?.id}
					section={activeSection}
					editView={false}
					onAbort={() => { showMultipleAssignmentFormModal(false); }}
				/>
			</Modal>

			<Grid
				container
				direction="row"
				justifyContent="space-between"
				sx={{ marginBottom: 2 }}
			>
				<div className='section'>
					<SectionTitle style={{ marginBottom: '0px' }}>
						{(showAssignmentHeader ? (
							<div>
								<Icon name='Assignments' />
								<span>{translate('tasks-header-overview')}</span>
								{renderCreateAssignmentButton()}
								<Link to="#" onClick={() => history.push(`${getRootUrl()}assignments`)} className='see-more'>
									{translate('show-all')}
								</Link>
							</div>
						) : null)}
					</SectionTitle>
				</div>
			</Grid>

			<Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
				<Grid item xs={12} md={7}>
					<TabContext value={currentSelectedTab}>
						<TabList onChange={(event, newValue) => onTabChange(event, newValue)}>
							<Tab label={translate('My assignments')} value="#assignments" />
							{isTeacher && <Tab label={translate('Assignment log')} value="#logs" />}
							{isTeacher && groupId != null && groupId > 0 && <Tab label={translate('Student overview')} value="#assignmentStudentsProgress" />}
						</TabList>
					</TabContext>
				</Grid>
				{currentSelectedTab === "#assignments" && (
					<Grid item xs={12} md={5} sx={{ mt: { xs: '2rem', md: '0rem' } }} container justifyContent={{ xs: 'flex-start', md: 'flex-end' }} alignItems="center">
						<Stack direction="row" spacing={2}>
							<ToggleButtonGroup value={currentLayout} onChange={(event, newValue) => setLayout(newValue)} exclusive>
								<Stack spacing={0}>
									<ToggleButton value="old_assignment_layout" aria-label="laptop">
										<WidgetsIcon />
									</ToggleButton>
								</Stack>
								<Stack spacing={0}>
									<ToggleButton value="new_assignment_layout" aria-label="tv">
										<TableRowsIcon />
									</ToggleButton>
								</Stack>
							</ToggleButtonGroup>
						</Stack>
					</Grid>
				)}
			</Grid>

			{
				currentSelectedTab == "#assignments" && (
					<>
						{currentLayout == 'old_assignment_layout' ? renderOldLayout(groupId) : null}
						{currentLayout == 'new_assignment_layout' ? renderNewTableLayout(groupId) : null}
					</>
				)
			}


			{
				currentSelectedTab == "#logs" ? <>
					<MyAssignmentsLogs groupId={groupId} />
				</> : null
			}


			{
				currentSelectedTab == "#assignmentStudentsProgress" && groupId != null && groupId > 0 ? <>
					<AssignmentsStudentsProgress groupId={groupId} /> </> : null
			}
		</div >
	);
}

export default MyAssignmentsWrapper;