import React from 'react';

const AssignmentTaskStatusNotAssigned = React.memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 37.4 37"
			xmlSpace="preserve"
		>
			<path
				style={{
					fill: "none",
					stroke: "#a0a2a5",
					strokeWidth: 4,
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeMiterlimit: 10,
				}}
				d="M7.9 18.5h21.6"
			/>
		</svg>
	);
});

export default AssignmentTaskStatusNotAssigned;